/* =============================== Faculty Css Start ================================= */
.check-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px;
}

.text-up {
    position: absolute;
    inset-inline-end: 32px;
    inset-block-end: calc(100% - 24px);
}
/* =============================== Faculty Css End ================================= */