// =========================== Star Rating Css Start =============================

#half-star-rating {
    .rating-group {
        display: inline-flex;
    }
    /* make hover effect work properly in IE */
    .rating__icon {
        pointer-events: none;
    }
    
    /* hide radio inputs */
    .rating__input {
        position: absolute !important;
        inset-inline-start: -9999px !important;
    }
    
    /* set icon padding and size */
    .rating__label {
        cursor: pointer;
        /* if you change the left/right padding, update the margin-right property of .rating__label--half as well. */
        padding: 0 0.1em;
        font-size: 2rem;
    }
    
    /* add padding and positioning to half star labels */
    .rating__label--half {
        padding-inline-end:0;
        margin-inline-end:-36px;
        z-index: 2;
        clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    }
    
    /* set default star color */
    .rating__icon--star {
        color: orange;
    }
    
    /* set color of none icon when unchecked */
    .rating__icon--none {
        color: #eee;
    }
    
    /* if none icon is checked, make it red */
    .rating__input--none:checked + .rating__label .rating__icon--none {
        color: red;
    }
    
    /* if any input is checked, make its following siblings grey */
    .rating__input:checked ~ .rating__label .rating__icon--star {
        color: #ddd;
    }
    
    /* make all stars orange on rating group hover */
    .rating-group:hover .rating__label .rating__icon--star,
    .rating-group:hover .rating__label--half .rating__icon--star {
        color: orange;
    }
    
    /* make hovered input's following siblings grey on hover */
    .rating__input:hover ~ .rating__label .rating__icon--star,
    .rating__input:hover ~ .rating__label--half .rating__icon--star {
        color: #ddd;
    }
    
    /* make none icon grey on rating group hover */
    .rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
        color: #eee;
    }
    
    /* make none icon red on hover */
    .rating__input--none:hover + .rating__label .rating__icon--none {
        color: red;
        }
    }
// =========================== Star Rating Css End =============================
