/* ==================================== Banner Three Css Start ================================ */
.linear-overlay {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        inset-inline-start: 0;
        inset-block-start: 0;
        background: linear-gradient(90deg, rgba(7, 20, 49, 0.85) 0%, rgba(7, 20, 49, 0.7) 45.64%, rgba(7, 20, 49, 0.3) 61.57%, rgba(7, 20, 49, 0.1) 71.73%, rgba(7, 20, 49, 0) 100%);
        z-index: -1;
    }
}

.banner-three {
    .slick-slider .slick-slide {
        margin: 0;
    }
    &__item {
        padding-top: clampCal(80, 190);
        padding-bottom: 190px;
    }

    @media (min-width: 1400px) {
        .slick-arrow-prev {
            margin-inline-start: 60px !important;
        }
        
        .slick-arrow-next {
            margin-inline-end: 60px !important;
        }
    }
}
/* ==================================== Banner Three Css End ================================ */